import React from 'react';
import './loadingSpinner.css';

const LoadingSpinner = () => {
  return (
    <>
      <div className="lds-dual-ring"></div>
    </>
  );
};

export default LoadingSpinner;
